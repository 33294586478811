import React, { useState } from 'react';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import classNames from 'classnames';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';

import {
	getMultipleTransitionsWithWillChange,
	gv,
	ns,
	proseCss,
} from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FaqHint = styled.span`
	height: 1em;
	width: 1em;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	margin: 0;
	background: ${gv('secondaryAccentColor')};
	color: ${gv('primaryBgText')};
	flex: 0 0 1em;
	svg {
		width: 10px !important;
		display: block;
	}
`;

const FaqTitleText = styled.span`
	flex: 0 0 calc(100% - 1em);
	padding: 0 0 0 ${props => ns(props.theme.gutter)};
`;

const FaqTitle = styled.h4`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large3)};
	color: ${gv('textColor')};
	cursor: pointer;
	margin: 0;
	padding: ${props => ns(props.theme.gutter)};
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
const FaqHider = styled.div`
	max-height: 0px;
	overflow: hidden;
	transition: ${props =>
		getMultipleTransitionsWithWillChange(['max-height'], '800ms ease-out')};
	&.active {
		max-height: 650px;
	}
`;
const FaqContent = styled.div`
	margin: 0;
	padding: 0;
	padding-top: ${props => ns(props.theme.gutter)};
	padding-bottom: ${props => ns(props.theme.gutter * 1.5)};
	padding-left: ${props => ns(props.theme.gutter)};
	${proseCss};
	font-size: ${props => ns(props.theme.fz.base)};
	line-height: 1.5;
`;

const FaqBody = styled.article`
	border-top: 1px solid ${gv('borderColorLight')};
	border-bottom: 1px solid ${gv('borderColorLight')};
	margin-bottom: -1px;
	&:last-child {
		margin-bottom: 0;
	}
`;

const Container = styled.div``;

export type FAQsWidgetProps = {
	data: GatsbyTypes.LandingPageDataQuery['faqs'];
};
export default function FAQsWidget(props: FAQsWidgetProps) {
	const { data } = props;
	const [activeFaq, setActiveFaq] = useState<number>(1);

	return (
		<Container>
			{data.edges.map(({ node }, index) => (
				<FaqBody key={node.id}>
					<FaqTitle
						tabIndex={0}
						role="button"
						onClick={e => {
							e.preventDefault();
							setActiveFaq(index);
						}}
					>
						<FaqHint>
							<FontAwesomeIcon icon={index === activeFaq ? faMinus : faPlus} />
						</FaqHint>
						<FaqTitleText>{node.frontmatter?.title}</FaqTitleText>
					</FaqTitle>
					<FaqHider className={classNames({ active: index === activeFaq })}>
						<FaqContent>
							<MDXRenderer>{node.body}</MDXRenderer>
						</FaqContent>
					</FaqHider>
				</FaqBody>
			))}
		</Container>
	);
}
