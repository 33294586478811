import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import { Constrain, GeneralBody } from '../components/Containers';
import { Article, Divider, PageTitle } from '../components/Typography';
import FAQsWidget from '../components/FAQs/Widget';
import NewSupport from '../components/NewSupport';

export default function Features(
	props: PageProps<GatsbyTypes.FAQSPageDataQuery>
) {
	const { data } = props;
	return (
		<>
			<Seo
				title="WPEForm Features - Why WPEForm is best WordPress Form Builder"
				description="Explore all the features and perks WPEForm WordPress Form Builder has to offer. From various form elements, to payments, subscription, quizzes and integrations, we have you covered."
			/>
			<Constrain className="narrow">
				<GeneralBody>
					<PageTitle className="centered narrow">
						Frequently Asked Questions
					</PageTitle>
					<Article className="subheader">
						Do you have a question about WPEForm? Please check the most
						frequently asked questions below. If this doesn't answer your
						question, please contact us.
					</Article>
					<Divider />
					<FAQsWidget data={data.faqs} />
					<NewSupport type="regular" />
				</GeneralBody>
			</Constrain>
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query FAQSPageData {
		faqs: allMdx(
			filter: { fileAbsolutePath: { glob: "**/site-data/faqs/**/*.mdx" } }
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						title
					}
					body
				}
			}
		}
	}
`;
